import { createRouter, createWebHistory } from "vue-router";

// Needs updating on permissions
function guardMyAdminRoute(to, from, next) {
  let hasToken = localStorage.getItem("token");
  let isAdminAuthenticated;

  if (localStorage.getItem("role") == 3 && hasToken) {
    isAdminAuthenticated = true;
  } else {
    isAdminAuthenticated = false;
  }
  if (isAdminAuthenticated) {
    next();
  } else {
    next("/dashboard"); // pushed back home;
  }
}

//Used to access all pages after login
function guardMyRoute(to, from, next) {
  // Grab the token value
  let loggedIn = JSON.parse(localStorage.getItem("loggedUser"));
  let hasToken = localStorage.getItem("token");

  //Create a local var
  let isAuthenticated = false;

  //Check the loggedIn var is not false
  if (loggedIn !== false && hasToken)
    // Set Local to true
    isAuthenticated = true;
  //If false set local to false
  else isAuthenticated = false;

  //Handle route destination
  if (isAuthenticated) {
    //Move forward
    next();
  } else {
    //Push to login
    next("/");
  }
}

function viewAssetGuard(to, from, next) {
  // Grab the token value
  let loggedIn = JSON.parse(localStorage.getItem("loggedUser"));
  let hasToken = localStorage.getItem("token");

  //Create a local var
  let isAuthenticated = false;

  //Check the loggedIn var is not false
  if (loggedIn !== false && hasToken)
    // Set Local to true
    isAuthenticated = true;
  //If false set local to false
  else isAuthenticated = false;

  //Handle route destination
  if (isAuthenticated) {
    //Move forward
    next();
  } else {
    //Push to login
    next(`/viewassetlogin/${to.params.id}`);
  }
}

const routes = [
  // User Journey
  {
    path: "/",
    name: "",
    component: () => import("../userJourney/views/LoginFormView.vue"),
    meta: {
      title: "SpacePlus - Login",
      metaTags: [
        {
          name: "description",
          content:
            "SpacePlus - Login to manage your assets and access the virtual tours",
        },
      ],
    },
  },
  {
    path: "/viewassetlogin/:id",
    name: "ViewAsset",
    component: () => import("../userJourney/views/ViewAssetLoginView.vue"),
    meta: {
      title: "SpacePlus - View Asset",
      metaTags: [
        {
          name: "description",
          content: "SpacePlus - View Asset",
        },
      ],
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("../userJourney/views/LogoutView.vue"),
    meta: {
      title: "SpacePlus - Logout",
    },
  },
  {
    path: "/invite-signup",
    name: "invite-signup",
    component: () => import("../userJourney/views/CreateAccountFormView.vue"),
    meta: {
      title: "SpacePlus - Invite Signup",
      metaTags: [
        {
          name: "description",
          content:
            "SpacePlus - Create Account to manage your assets and access the virtual tours",
        },
      ],
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../components/UserProfile.vue"),
    meta: {
      title: "SpacePlus - Profile",
      metaTags: [
        {
          name: "description",
          content: "SpacePlus - Update Profile",
        },
      ],
    },
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: () => import("../userJourney/views/ForgotPasswordFormView.vue"),
    meta: {
      title: "SpacePlus - Forgot Password",
      metaTags: [
        {
          name: "description",
          content: "SpacePlus - Forgot Password",
        },
      ],
    },
  },
  {
    path: "/reset",
    name: "resetpassword",
    component: () => import("../userJourney/views/ResetPasswordFormView.vue"),
    meta: {
      title: "SpacePlus - Reset Password",
      metaTags: [
        {
          name: "description",
          content:
            "SpacePlus - Login to manage your assets and access the virtual tours",
        },
      ],
    },
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/HomeDashboardView.vue"),
    beforeEnter: guardMyRoute,
    meta: {
      title: "SpacePlus - Dashboard",
      metaTags: [
        {
          name: "description",
          content: "SpacePlus - Welcome to SpacePlus",
        },
      ],
    },
  },
  {
    path: "/assets",
    name: "assets",
    component: () => import("../views/AssetTableView.vue"),
    beforeEnter: guardMyRoute,
    meta: {
      title: "SpacePlus - Assets",
      metaTags: [
        {
          name: "description",
          content: "SpacePlus - Asset register",
        },
      ],
    },
  },
  {
    path: "/assets/search/:id",
    name: "SearchAsset",
    component: () => import("../views/SearchAssetView.vue"),
    beforeEnter: viewAssetGuard,
    meta: {
      title: "SpacePlus - View Asset",
      metaTags: [
        {
          name: "description",
          content: "SpacePlus - Search Asset by ref",
        },
      ],
    },
  },
  {
    path: "/clients",
    name: "clients",
    component: () => import("../views/ClientTableView.vue"),
    beforeEnter: guardMyAdminRoute,
    meta: {
      title: "SpacePlus - Clients",
      metaTags: [
        {
          name: "description",
          content: "SpacePlus - Client register",
        },
      ],
    },
  },
  {
    path: "/clients/:id/sites",
    name: "view client sites",
    component: () => import("../views/ClientSitesView.vue"),
    beforeEnter: guardMyAdminRoute,
    meta: {
      title: "SpacePlus - View Client Sites",
      metaTags: [
        {
          name: "description",
          content: "SpacePlus - Client Sites",
        },
      ],
    },
  },
  {
    path: "/users",
    name: "users",
    component: () => import("../views/ContractorTableView.vue"),
    beforeEnter: guardMyAdminRoute,
    meta: {
      title: "SpacePlus - View Users",
      metaTags: [
        {
          name: "description",
          content: "SpacePlus - User register",
        },
      ],
    },
  },
  {
    path: "/sites",
    name: "sites",
    component: () => import("../views/SiteTableView.vue"),
    beforeEnter: guardMyRoute,
    meta: {
      title: "SpacePlus - Spaces",
      metaTags: [
        {
          name: "description",
          content: "SpacePlus - Space register",
        },
      ],
    },
  },
  {
    path: "/site/:id",
    name: "viewsite",
    component: () => import("../views/ViewSiteView.vue"),
    beforeEnter: guardMyRoute,
    meta: {
      title: "SpacePlus - View Space",
      metaTags: [
        {
          name: "description",
          content: "SpacePlus - View Space by id",
        },
      ],
    },
  },
  {
    path: "/sites/search/:id",
    name: "SearchSite",
    component: () => import("../views/SearchSiteView.vue"),
    beforeEnter: guardMyRoute,
    meta: {
      title: "SpacePlus - Search Space",
      metaTags: [
        {
          name: "description",
          content: "SpacePlus - Search Space by uprn",
        },
      ],
    },
  },
  {
    path: "/maintenance/:siteid/:assetid/:maintenanceid/:clientid",
    name: "maintenanceactions",
    component: () => import("../views/MaintenanceActionsView.vue"),
    props: true,
    beforeEnter: guardMyRoute,
    meta: {
      title: "SpacePlus - View Space",
      metaTags: [
        {
          name: "description",
          content: "SpacePlus - Maintenance for Space",
        },
      ],
    },
  },
  {
    path: "/bundle-requests",
    name: "bundle-requests",
    component: () => import("../views/BundleTableView.vue"),
    beforeEnter: guardMyAdminRoute,
    meta: {
      title: "SpacePlus - Bundle Requests",
      metaTags: [
        {
          name: "description",
          content: "SpacePlus - Bundle Requests",
        },
      ],
    },
  },
  {
    path: "/404",
    name: "NotFound",
    component: () => import("../views/NotFoundView.vue"),
    beforeEnter: guardMyRoute,
    meta: {
      title: "SpacePlus - 404 Not Found",
      metaTags: [
        {
          name: "description",
          content: "SpacePlus - 404 Not Found",
        },
      ],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
