import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";
import "./assets/tooltip.css";
import "./assets/tailwind.css";
import "./assets/menubar.css";
import store from "./store";

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(FloatingVue, {
    themes: {
      "info-tooltip": {
        $extend: "tooltip",
        placement: "bottom",
        delay: {
          show: 0,
          hide: 0,
        },
        triggers: ["hover"],
      },
      "progress-tooltip": {
        $extend: "tooltip",
        placement: "top",
        delay: {
          show: 0,
          hide: 0,
        },
        triggers: ["hover"],
      },
    },
  })
  .mount("#app");
