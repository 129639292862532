import { createStore } from "vuex";

export default createStore({
  state: {
    user: {
      organisation: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      // other user info
    },
    subscriptionPlan: "",
  },
  mutations: {
    SET_USER_INFO(state, userInfo) {
      state.user = userInfo;
    },
    SET_SUBSCRIPTION_PLAN(state, plan) {
      state.subscriptionPlan = plan;
    },
  },
  actions: {
    setUserInfo({ commit }, userInfo) {
      commit("SET_USER_INFO", userInfo);
    },
    setSubscriptionPlan({ commit }, plan) {
      commit("SET_SUBSCRIPTION_PLAN", plan);
    },
  },
});
